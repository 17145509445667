@font-face {
  font-family: 'Montserrat';
  src: url('./common/fonts/Montserrat-Light.ttf');
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('./common/fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat-Variable';
  src: url('./common/fonts/Montserrat-VariableFont_wght.ttf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-family: 'Montserrat';
}

.swal2-html-container,
.swal2-actions {
  font-family: 'Montserrat';
}

#swal2-title {
  font-family: 'Montserrat';
}

html {
  scroll-behavior: smooth;
}